import { useMemo } from 'react';
import { useRouter } from 'next/compat/router';
import { cn } from '@virginexperiencedays/tailwind';
import { SliceZone } from '@prismicio/react';

import { getPageComponents } from '@virginexperiencedays/cms/constants/slices';
import { ByPassBlock } from '@virginexperiencedays/components-v2/src/ByPassBlock';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { RichTextWrap } from '@virginexperiencedays/components-v2/src/typography/RichTextWrap';
import { Stack } from '@virginexperiencedays/components-v2/src/layout/Stack';

import DynamicYield from '../../DynamicYield/DynamicYield';
import HomePageSEO, { HomePageSEOType } from './HomePageSEO';
import { DyContextPageType } from '@virginexperiencedays/dy';
import { bind as dangerouslyBindClickTracking } from '../search/tracking/clicks';
import { getPageMetaData } from '../../SEO/utils/metadata';
import { useIsProductCardsV2 } from '../search/ProductCard';
import { slice as trackSlice } from '../search/tracking/interactions';
import { useHomePageTracking } from './tracking/useHomePageTracking';
import { PAGE_TYPES } from '../../tracking/gtm/consts';
import { submit as onNewsletterSubmit } from '../../../utils/newsletter';

import type { RichTextField, RTHeading1Node } from '@prismicio/types';

import type { BasePageProps as PageProps } from '../types';

const Page = ({ isDarkTheme, prismicPage, prismicSlices, slug, listOfLinks }: PageProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const router = useRouter();
  const pageTitle: RichTextField = prismicPage?.data?.page_title ?? null;
  const seo: HomePageSEOType = getPageMetaData(prismicPage?.data);

  const isSlicesExisting = !!prismicSlices?.length;
  const pageTitleObject =
    Array.isArray(pageTitle) && pageTitle.length && (pageTitle?.[0] as RTHeading1Node);
  const mainContentId = 'home-main-content';
  const pageType = PAGE_TYPES.Home;

  const pageComponents = useMemo(() => getPageComponents(), []);

  useHomePageTracking({ slices: prismicSlices });
  prismicSlices = dangerouslyBindClickTracking({
    slices: prismicSlices,
    slug: 'home',
    next: (url) => router.push(url),
    hasGAEvent: false,
    pageType,
    isProductCardsV2,
  });

  return (
    <>
      <HomePageSEO {...seo} />
      <DynamicYield type={DyContextPageType.HOMEPAGE} />
      <ByPassBlock mainContentId={mainContentId} />
      <Container
        id={mainContentId}
        className={cn(
          'flex flex-row flex-nowrap items-center justify-center py-4',
          '[&_h1]:m-0 [&_h1]:text-center [&_h1]:font-inter [&_h1]:text-sm [&_h1]:leading-[1.4] [&_h1]:text-neutral-strong'
        )}
      >
        <RichTextWrap field={pageTitle} />
      </Container>
      {isSlicesExisting && (
        <Stack>
          <SliceZone
            slices={prismicSlices}
            components={pageComponents}
            context={{
              router,
              slug: slug === '/' ? 'home' : slug,
              lastSlug: 'home',
              hide: false,
              pageTitle: pageTitleObject?.text ?? '',
              pageType,
              listOfLinks,
              isDarkTheme,
              isProductCardsV2,
              trackSlice,
              onNewsletterSubmit,
              overridesSlugInfo: null,
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default Page;
